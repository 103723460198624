table {
    border-collapse: separate;
    border-spacing: 5px;
    width: 100%;
  }
  
  table th,
  table td {
    padding: 10px;
  }
  
  table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  table td {
    border: 1px solid #ddd;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 5px;
  }
  
  .add-input-button {
    margin-top: 10px;
  }
  